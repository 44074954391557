@import-normalize; /* Added according to https://create-react-app.dev/docs/adding-css-reset */

@font-face {
  font-family: 'Open Sans';
  src: url(../../assets/fonts/OpenSans-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans';
  src: url(../../assets/fonts/OpenSans-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url(../../assets/fonts/OpenSans-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Century Gothic Pro';
  src: url(../../assets/fonts/Century-Gothic.otf);
  font-weight: 400;
}

@font-face {
  font-family: 'Century Gothic Pro';
  src: url(../../assets/fonts/Century-Gothic-Bold.otf);
  font-weight: 700;
}

html, body {
  background-color: #ebebeb;
  color: #181a1a;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin: 0;
  min-height: 100vh;
  padding: 0;
}


@media (max-width: 600px) {
  body {
    background-color: white;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}